import { HttpPost } from '@/utils/request'

// 订单列表
export async function orderListApi (options) {
  return await HttpPost('/order/index', options)
}

// 添加订单
export async function addOrderApi (options) {
  return await HttpPost('/order/addOrder', options)
}

// 服务记录详情
export async function serviceRecordDetailApi (options) {
  return await HttpPost('/order/serviceRecordDetail', options)
}

// 设置服务时长
export async function setServiceTimeApi (options) {
  return await HttpPost('/order/setServiceTime', options)
}

// 付款短信计划
export async function paySmsPlanApi (options) {
  return await HttpPost('/order/paySmsPlan', options)
}

// 发送付款短信
export async function sendPaySmsApi (options) {
  return await HttpPost('/order/sendPaySms', options)
}

// 咨询列表
export async function consultListApi (options) {
  return await HttpPost('/order/consult', options)
}
