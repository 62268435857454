<template>
  <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="show"
      custom-class="drawer"
      ref="drawer"
      size="50%"
  >
    <div class="demo-drawer__content">
      <el-form :model="drawerForm" label-width="100px">
        <el-form-item label="订单ID" v-if="drawerForm.order_id">
          <el-input disabled v-model="drawerForm.order_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="服务手机">
          <el-autocomplete style="width: 100%"
              class="inline-input"
              v-model="drawerForm.service_mobile"
              :fetch-suggestions="querySearch"
              placeholder="请输入服务手机"
          ></el-autocomplete>
        </el-form-item>
        <template v-if="!drawerForm.order_id">
          <el-form-item label="用户手机">
            <el-input v-model="drawerForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="服务项目">
            <el-select style="width: 100%" v-model="drawerForm.service_item_id" placeholder="请选择服务项目">
              <el-option
                  v-for="item of serviceItem"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <slot></slot>
        <template v-if="drawerForm.order_id">
          <el-form-item v-indentify="'consult:list:view:paySmsPlan'" label="短信计划">
            <div class="order-sms">
              <el-button type="success" @click="seeSms" :loading="seeSmsLoading">查看短信计划</el-button>
              <el-button v-indentify="'consult:list:view:sendPaySms'" @click="sendSmsVisible = true" icon="el-icon-chat-line-round" type="primary">发送短信</el-button>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button v-indentify="'order:list:view:setServiceTime'" type="primary" @click="submit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </div>
    <el-drawer
        title="短信计划"
        destroy-on-close
        :append-to-body="true"
        :visible.sync="innerDrawer">
      <el-timeline>
        <el-timeline-item v-for="item in smsList" :key="item.id" :timestamp="$dateFormat(item.pre_send_time)" placement="top">
          <el-card>
            <h4>发送状态：<StatusTag :state="item.status" :data="smsPlanStatusEnum"/></h4>
            <p>计划发送时间: {{ item.pre_send_time | dateFormat }}</p>
            <p>来源: {{ sourceEnum[item.source] }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
    <el-dialog title="发送付款短信" :visible.sync="sendSmsVisible" :append-to-body="true">
      <el-form>
        <el-form-item label="发送时间" label-width="100px">
          <el-date-picker
              style="width: 100%;"
              v-model="pre_send_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="选择发送时间，不选此刻发送">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="offSendSms">取 消</el-button>
        <el-button type="primary" @click="sendSms">确 定</el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { addOrderApi, paySmsPlanApi, sendPaySmsApi, setServiceTimeApi } from '@/api/order'
import StatusTag from '@/components/pc/common/StatusTag'
import { timeComparison } from '@/utils/day'

export default {
  name: 'pcOrderDrawerComponents',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    },
    drawerForm: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('selectOptions', ['serviceItem']),
    ...mapState('statusTagEnum', ['smsPlanStatusEnum']),
    ...mapState('config', ['serviceMobile']),
    show: {
      get () {
        return this.$props.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  components: {
    StatusTag
  },
  data () {
    return {
      loading: false,
      innerDrawer: false,
      sendSmsVisible: false,
      seeSmsLoading: false,
      smsList: [],
      sourceEnum: {
        1: '系统',
        2: '手动'
      },
      pre_send_time: ''
    }
  },
  methods: {
    querySearch (queryString, cb) {
      const restaurants = this.serviceMobile
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    // 关闭
    cancelForm () {
      this.loading = false
      this.show = false
      this.date = []
      this.$emit('cancel')
    },
    // 关闭前
    async handleClose (done) {
      if (this.loading) {
        this.$message.info('正在提交，请稍后再关闭！')
        return
      }
      await this.$confirm('确定关闭吗？')
      this.cancelForm()
    },
    async submit () {
      const { drawerForm, flag } = this.$props
      let data = null
      let msg = ''
      if (this.loading) {
        return
      }
      if (!timeComparison(drawerForm.service_start_time, drawerForm.service_end_time)) {
        this.$message.error('结束时间不可少于开始时间')
        return
      }
      this.loading = true
      try {
        if (flag === 'add') {
          data = await addOrderApi(drawerForm)
          msg = '添加'
        } else {
          data = await setServiceTimeApi(drawerForm)
          msg = '编辑'
        }
        if (data.code === 1) {
          this.cancelForm()
          this.$message.success(msg + '订单成功！！！')
          this.$emit('confirm')
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 查看短信列表
    async seeSms () {
      this.seeSmsLoading = true
      const { order_id } = this.drawerForm
      const data = await paySmsPlanApi({ order_id })
      if (data.code === 1) {
        this.smsList = data.data
        this.innerDrawer = true
      }
      this.seeSmsLoading = false
    },
    // 发送短信
    async sendSms () {
      const { order_id } = this.drawerForm
      const { pre_send_time } = this.$data
      const data = await sendPaySmsApi({
        order_id, pre_send_time
      })
      if (data.code === 1) {
        this.$message.success('添加短信计划成功！')
      }
      this.offSendSms()
    },
    // 关闭发送信息弹窗
    offSendSms () {
      this.sendSmsVisible = false
      this.pre_send_time = ''
    }
  },
  watch: {
    drawerForm: {
      handler (newValue, oldValue) {
        this.drawerForm = newValue
      },
      deep: true
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-drawer__body {
  padding: 20px;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  form {
    flex: 1;
  }
  .demo-drawer__footer {
    display: flex;
     button {
      flex: 1;
    }
  }
}
</style>
